import { cn } from '@firstbase/utils'
import React from 'react'

interface CharacterCountProps extends React.HTMLAttributes<HTMLSpanElement> {
  value: string
  maxLength: number
}

const CharacterCount = React.forwardRef<
  HTMLSpanElement,
  CharacterCountProps
>(({ value, maxLength, className, ...props }, ref) => (
    <span
      ref={ref}
      className={cn('text-xs text-muted-foreground', className)}
      {...props}
    >
      {value.length} / {maxLength}
    </span>
  ))
CharacterCount.displayName = 'CharacterCount'

export { CharacterCount }
