import { gql } from '@apollo/client'

export const PACKAGE_BASICS = gql`
  fragment PackageBasics on Package {
    id
    name
    slug
    approvalRequired
    automaticOrder
    active
  }
`

export const ORGANIZATIONPERSON_PROFILE_BASIC = gql`
  fragment OrganizationPersonProfileBasic on OrganizationPerson {
    forename
    surname
    email
    secondaryEmail
    packages {
      name
      slug
    }
    appAccess
    status
    startDate
    address {
      addressLine1
      addressLine2
      locality
      administrativeArea
      postalCode
      countryCode
      phoneNumber
    }
  }
`

export const SKU_BASICS = gql`
  fragment SKUBasics on SKU {
    id
    vendor {
      id
      code
      name
      active
    }
    vendorSku
    upc
    ean
    category {
      id
      nameSingular
      namePlural
      code
      precedence
      active
      skuType
    }
    metadata {
      id
      field
      value
    }
    availableCountries
    skuInformation {
      productTitle
    }
    skuImages
    numberOfBoxes
    dropShipOnly
    material
    microphoneIncluded
    refreshRate
    resolution
    wireless
    layout
    leadTimes {
      minLeadTime
      maxLeadTime
      region
    }
  }
`

export const RULE_BASICS = gql`
  ${SKU_BASICS}
  fragment RuleBasics on PackageRule {
    id
    quantity
    optional
    category {
      id
      nameSingular
      namePlural
      code
      precedence
      active
      skuType
    }
    allowedProducts {
      ...SKUBasics
    }
  }
`

export const NOTE_BASICS = gql`
  fragment NoteBasics on PackageNote {
    id
    note
  }
`

export const PRODUCT_PRICING_ITEM = gql`
  ${SKU_BASICS}

  fragment ProductPricingItem on ProductPricingItem {
    id
    sku {
      ...SKUBasics
    }
    pricing {
      regionCode
      currencyCode
      price
      priceOption
    }
    firstBaseSupplied
  }
`
